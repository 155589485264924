import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store/store";
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/",
    name: "layout-view",
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "home-view",
        meta: {
          title: "首页",
        },
        component: () => import("@/views/index/Home.vue"),
      },
      {
        path: "/tallyBind",
        name: "tally-bind",
        meta: {
          title: "理货绑定",
        },
        component: () => import("@/views/label/TallyBind.vue"),
      },
      {
        path: "/orderOut",
        name: "order-out",
        meta: {
          title: "订单出库",
        },
        component: () => import("@/views/label/OrderOut.vue"),
      },
      {
        path: "/test",
        name: "test-view",
        meta: {
          title: "测试",
        },
        component: () => import("@/views/test/Test.vue"),
      },
      {
        path: "/location",
        name: "wms-location",
        meta: {
          title: "仓库库位",
        },
        component: () => import("@/views/basedata/WmsLocation.vue"),
      },
      {
        path: "/redirect",
        name: "redirect-page",
        meta: {
          title: "重定向",
        },
        component: () => import("@/views/basedata/Redirect.vue"),
      },
      {
        path: "/takeNumberSet",
        name: "take-number-set",
        meta: {
          title: "取号设置",
        },
        component: () => import("@/views/basedata/TakeNumberSet.vue"),
      },
      {
        path: "/courierCompany",
        name: "courier-company",
        meta: {
          title: "快递公司",
        },
        component: () => import("@/views/basedata/CourierCompany.vue"),
      },
      {
        path: "/measurement",
        name: "measurement",
        meta: {
          title: "计量单位",
        },
        component: () => import("@/views/basedata/Measurement.vue"),
      },
      {
        path: "/countries",
        name: "countries",
        meta: {
          title: "国别管理",
        },
        component: () => import("@/views/basedata/Countries.vue"),
      },
      {
        path: "/consumables",
        name: "consumables",
        meta: {
          title: "耗材管理",
        },
        component: () => import("@/views/basedata/Consumables.vue"),
      },
      {
        path: "/wmsLocalArea",
        name: "wmsLocal-area",
        meta: {
          title: "仓库区域",
        },
        component: () => import("@/views/basedata/WmsLocalArea.vue"),
      },
      {
        path: "/goods",
        name: "goods",
        meta: {
          title: "商品列表（跨境）",
        },
        component: () => import("@/views/goods/Goods.vue"),
      },
      {
        path: "/goods-dm",
        name: "goods-dm",
        meta: {
          title: "商品列表（大贸）",
        },
        component: () => import("@/views/goods/GoodsDm.vue"),
      },
      {
        path: "/goodsCombination",
        name: "goodsCombination",
        meta: {
          title: "商品组合",
        },
        component: () => import("@/views/goods/GoodsCombination.vue"),
      },
      {
        path: "/platform",
        name: "platform",
        meta: {
          title: "平台管理",
        },
        component: () => import("@/views/custom/Platform.vue"),
      },
      {
        path: "/shipper",
        name: "shipper",
        meta: {
          title: "货主管理",
        },
        component: () => import("@/views/custom/Shipper.vue"),
      },
      {
        path: "/contract",
        name: "contract",
        meta: {
          title: "合约管理",
        },
        component: () => import("@/views/custom/Contract.vue"),
      },
      {
        path: "/pddStore",
        name: "pddStore",
        meta: {
          title: "拼多多店铺",
        },
        component: () => import("@/views/custom/PddStore.vue"),
      },

      {
        path: "/wdStore",
        name: "wdStore",
        meta: {
          title: "微店店铺",
        },
        component: () => import("@/views/custom/WdStore.vue"),
      },
      {
        path: "/freightTemplate",
        name: "freightTemplate",
        meta: {
          title: "运费模板",
        },
        component: () => import("@/views/custom/FreightTemplate.vue"),
      },
      {
        path: "/orderToDeclare",
        name: "orderToDeclare",
        meta: {
          title: "订单列表",
        },
        component: () => import("@/views/orderDeclare/OrderToDeclare.vue"),
      },
      // {
      //   path: "/orderError",
      //   name: "orderError",
      //   meta: {
      //     title: "异常日志",
      //   },
      //   component: () => import("@/views/orderDeclare/OrderError.vue"),
      // },
      {
        path: "/declareOrder",
        name: "declareOrder",
        meta: {
          title: "清单列表",
        },
        component: () => import("@/views/declareOrder/DeclareOrder.vue"),
      },
      {
        path: "/orderBill",
        name: "orderBill",
        meta: {
          title: "电子税单",
        },
        component: () => import("@/views/declareOrder/OrderBill.vue"),
      },
      {
        path: "/orderBillItem",
        name: "orderBillItem",
        meta: {
          title: "电子税单详情",
        },
        component: () => import("@/views/declareOrder/OrderBillItem.vue"),
      },
      // {
      //   path: "/orderBillStatus",
      //   name: "order-bill-status",
      //   meta: {
      //     title: "缴款书",
      //   },
      //   component: () => import("@/views/declareOrder/OrderBillStatus.vue"),
      // },
      {
        path: "/declareOrderDm",
        name: "declareOrderDm",
        meta: {
          title: "订单列表（大贸）",
        },
        component: () => import("@/views/declareOrderDm/DeclareOrderDm.vue"),
      },
      {
        path: "/returnGoodsRegistration",
        name: "returnGoodsRegistration",
        meta: {
          title: "退货登记",
        },
        component: () =>
          import("@/views/returnGoodsRegistration/ReturnGoodsRegistration.vue"),
      },
      {
        path: "/cancellations",
        name: "cancellations",
        meta: {
          title: "撤单列表",
        },
        component: () =>
          import("@/views/returnGoodsRegistration/Cancellations.vue"),
      },
      {
        path: "/returnGoods",
        name: "returnGoods",
        meta: {
          title: "退货管理",
        },
        component: () =>
          import("@/views/returnGoodsRegistration/ReturnGoods.vue"),
      },
      {
        path: "/expressRegistration",
        name: "expressRegistration",
        meta: {
          title: "订单登记",
        },
        component: () =>
          import("@/views/returnGoodsRegistration/ExpressRegistration.vue"),
      },
      {
        path: "/returnListing",
        name: "returnListing",
        meta: {
          title: "退货列表",
        },
        component: () =>
          import("@/views/returnGoodsRegistration/ReturnListing.vue"),
      },
      {
        path: "/transferOrder",
        name: "transferOrder",
        meta: {
          title: "转仓订单",
        },
        component: () => import("@/views/transferOrder/TransferOrder.vue"),
      },
      {
        path: "/transferOrderDetail",
        name: "transferOrderDetail",
        meta: {
          title: "转仓详情",
        },
        component: () =>
          import("@/views/transferOrder/TransferOrderDetail.vue"),
      },
      {
        path: "/outboundOrderDm",
        name: "outbound-order-dm",
        meta: {
          title: "出库单列表（大贸）",
        },
        component: () => import("@/views/outboundOrderDm/OutboundOrderDm.vue"),
      },
      {
        path: "/outboundOrderDmDetail",
        name: "outbound-order-detail-dm",
        meta: {
          title: "出库单详情（大贸）",
        },
        component: () =>
          import("@/views/outboundOrderDm/OutboundOrderDmDetail.vue"),
      },
      {
        path: "/outboundOrder",
        name: "outbound-order",
        meta: {
          title: "出库单列表（跨境）",
        },
        component: () => import("@/views/outboundOrder/OutboundOrder.vue"),
      },
      {
        path: "/outboundOrderDetail",
        name: "outbound-order-detail",
        meta: {
          title: "出库单详情（跨境）",
        },
        component: () =>
          import("@/views/outboundOrder/OutboundOrderDetail.vue"),
      },
      {
        path: "/outboundOrderPicking",
        name: "outboundOrderPicking",
        meta: {
          title: "拣货任务",
        },
        component: () =>
          import("@/views/warehouseWorkbench/OutboundOrderPicking.vue"),
      },

      {
        path: "/packagingMachine",
        name: "packaging-machine",
        meta: {
          title: "打包台",
        },
        component: () =>
          import("@/views/warehouseWorkbench/PackagingMachine.vue"),
      },
      {
        path: "/orderWeighing",
        name: "order-weighing",
        meta: {
          title: "订单称重",
        },
        component: () => import("@/views/warehouseWorkbench/OrderWeighing.vue"),
      },
      {
        path: "/orderWeighingBatch",
        name: "order-weighing-batch",
        meta: {
          title: "批量称重",
        },
        component: () =>
          import("@/views/warehouseWorkbench/OrderWeighingBatch.vue"),
      },
      {
        path: "/tally",
        name: "tally",
        meta: {
          title: "理货单管理",
        },
        component: () => import("@/views/tallyManagement/Tally.vue"),
      },
      {
        path: "/tallyDetail",
        name: "tally-detail",
        meta: {
          title: "理货单详情",
        },
        component: () => import("@/views/tallyManagement/TallyDetail.vue"),
      },
      // {
      //   path: "/security",
      //   name: "security",
      //   meta: {
      //     title: "防伪管理",
      //   },
      //   component: () => import("@/views/tallyManagement/Security.vue"),
      // },
      {
        path: "/noBondedInOut",
        name: "noBondedInOut",
        meta: {
          title: "非保出入库申报",
        },
        component: () => import("@/views/noBondedInOut/NoBondedInOut.vue"),
      },
      {
        path: "/batchGrnGoods",
        name: "batchGrnGoods",
        meta: {
          title: "批次库存",
        },
        component: () => import("@/views/stock/BatchGrnGoods.vue"),
      },
      {
        path: "/inventory",
        name: "inventory",
        meta: {
          title: "库存盘点",
        },
        component: () => import("@/views/stock/Inventory.vue"),
      },
      {
        path: "/grn",
        name: "grn",
        meta: {
          title: "入库单",
        },
        component: () => import("@/views/stock/Grn.vue"),
      },
      {
        path: "/grnGoodsAll",
        name: "grnGoodsAll",
        meta: {
          title: "产品库存（跨境）",
        },
        component: () => import("@/views/stock/GrnGoodsAll.vue"),
      },
      {
        path: "/grnGoods",
        name: "grnGoods",
        meta: {
          title: "库存详情（跨境）",
        },
        component: () => import("@/views/stock/GrnGoods.vue"),
      },
      {
        path: "/grnGoodsAllDm",
        name: "grnGoodsAllDm",
        meta: {
          title: "产品库存（大贸）",
        },
        component: () => import("@/views/stock/GrnGoodsAllDm.vue"),
      },
      {
        path: "/grnGoodsDm",
        name: "grnGoodsDm",
        meta: {
          title: "库存详情（大贸）",
        },
        component: () => import("@/views/stock/GrnGoodsDm.vue"),
      },
      {
        path: "/realTimeInventory",
        name: "real-time-inventory",
        meta: {
          title: "库存快照",
        },
        component: () => import("@/views/stock/RealTimeInventory.vue"),
      },
      {
        path: "/grnGoodsLog",
        name: "grn-goods-log",
        meta: {
          title: "库存记录",
        },
        component: () => import("@/views/stock/GrnGoodsLog.vue"),
      },

      {
        path: "/invtMessage",
        name: "invtMessage",
        meta: {
          title: "核住列表",
        },
        component: () => import("@/views/nuclearHousing/InvtMessage.vue"),
      },
      {
        path: "/passPortHead",
        name: "passPortHead",
        meta: {
          title: "核放单",
        },
        component: () => import("@/views/nuclearHousing/PassPortHead.vue"),
      },

      {
        path: "/orderReport",
        name: "order-report",
        meta: {
          title: "订单报表",
        },
        component: () => import("@/views/dataReport/OrderReport.vue"),
      },
      {
        path: "/grnReport",
        name: "grn-report",
        meta: {
          title: "批次报表",
        },
        component: () => import("@/views/dataReport/GrnReport.vue"),
      },
      // {
      //   path: "/locationSnapshot",
      //   name: "locationSnapshot",
      //   meta: {
      //     title: "库位快照",
      //   },
      //   component: () => import("@/views/dataReport/LocationSnapshot.vue"),
      // },
      {
        path: "/accountLog",
        name: "accountLog",
        meta: {
          title: "资金记录",
        },
        component: () => import("@/views/financialManagement/AccountLog.vue"),
      },
      {
        path: "/declareOrderLog",
        name: "declareOrderLog",
        meta: {
          title: "操作日志",
        },
        component: () => import("@/views/system/DeclareOrderLog.vue"),
      },
      {
        path: "/declareXml",
        name: "declareXml",
        meta: {
          title: "申报报文",
        },
        component: () => import("@/views/system/DeclareXml.vue"),
      },
      {
        path: "/declareShipper",
        name: "declareShipper",
        meta: {
          title: "报关客户配置",
        },
        component: () => import("@/views/system/DeclareShipper.vue"),
      },
      {
        path: "/orderDeclareConfig",
        name: "orderDeclareConfig",
        meta: {
          title: "订单申报配置",
        },
        component: () => import("@/views/system/OrderDeclareConfig.vue"),
      },
      {
        path: "/inventoryConfig",
        name: "inventoryConfig",
        meta: {
          title: "清单申报配置",
        },
        component: () => import("@/views/system/InventoryConfig.vue"),
      },
      {
        path: "/pconfigs",
        name: "pconfigs",
        meta: {
          title: "后台配置 ",
        },
        component: () => import("@/views/system/Pconfigs.vue"),
      },
      {
        path: "/pdaVersion",
        name: "pda-version",
        meta: {
          title: "PDA版本库",
        },
        component: () => import("@/views/system/PdaVersion.vue"),
      },
    ],
    component: () => import("@/views/Layout.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录",
    },
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/stosurfaceSingle",
    name: "stosurfaceSingle",
    meta: {
      title: "打印面单",
    },
    component: () => import("@/views/warehouseWorkbench/StosurfaceSingle.vue"),
  },
  {
    path: "/batchPrint",
    name: "batch-print",
    meta: {
      title: "批量打印",
    },
    component: () => import("@/views/warehouseWorkbench/BatchPrint.vue"),
  },
  {
    path: "/picking1",
    name: "picking1",
    meta: {
      title: "打印拣货单",
    },
    component: () => import("@/views/warehouseWorkbench/Picking1.vue"),
  },
  {
    path: "/sfsurfaceSingle",
    name: "sfsurfaceSingle",
    meta: {
      title: "打印顺丰面单",
    },
    component: () => import("@/views/warehouseWorkbench/SfsurfaceSingle.vue"),
  },
  {
    path: "/ztosurfaceSingle",
    name: "ztosurfaceSingle",
    meta: {
      title: "中通国际面单",
    },
    component: () => import("@/views/warehouseWorkbench/ZtosurfaceSingle.vue"),
  },
  {
    path: "/ztoNsurfaceSingle",
    name: "ztoNsurfaceSingle",
    meta: {
      title: "中通国内面单",
    },
    component: () => import("@/views/warehouseWorkbench/ZtoNsurfaceSingle.vue"),
  },
  {
    path: "/ztoZtoN",
    name: "ztoZtoN",
    meta: {
      title: "中通合并面单",
    },
    component: () => import("@/views/warehouseWorkbench/ZtoZtoN.vue"),
  },
  {
    path: "/printBatch",
    name: "printBatch",
    meta: {
      title: "批次号打印",
    },
    component: () => import("@/views/tallyManagement/PrintBatch.vue"),
  },
];

const router = new VueRouter({
  // mode: 'history',
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// 路由前置守卫
router.beforeEach((to, from, next) => {
  if (
    to.path != "/home" &&
    to.path != "/login" &&
    to.path != "/redirect" &&
    to.path
  ) {
    store.commit("addVisitedRoute", to);
  }
  next(); // 默认放行
});

export default router;
