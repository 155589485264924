import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/global.css";
import "@/utils/directive.js";
import store from "./store/store.js";
import router from "./router/index.js";
import { exportTool } from "@/utils/util";
Vue.prototype.$export = exportTool;
Vue.config.productionTip = false;
Vue.use(ElementUI, { size: "small" });
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
